@keyframes fadeIn-76252d8a {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
.opacity-enter-active[data-v-76252d8a] {
  animation: fadeIn-76252d8a .3s;
}
.opacity-leave-active[data-v-76252d8a] {
  animation: fadeIn-76252d8a .3s reverse;
}
.relative[data-v-76252d8a] {
  position: relative;
}
.w-full[data-v-76252d8a] {
  width: 100%;
}
.relative-center[data-v-76252d8a] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-76252d8a] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-76252d8a] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-76252d8a] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-76252d8a] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-76252d8a] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-76252d8a] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-76252d8a] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-76252d8a] {
  justify-content: space-between;
}
.flex[data-v-76252d8a] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-76252d8a] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-76252d8a],
.card-list-no-padding .ci-description[data-v-76252d8a] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.t-title-18[data-v-76252d8a] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 500;
  font-size: 0.09375rem;
  color: #fff;
  line-height: 0.09375rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.t-value-16[data-v-76252d8a] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #fff;
  line-height: 0.08333333rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
a[data-v-76252d8a] {
  text-decoration: none;
}
.map-marker[data-v-76252d8a] {
  position: relative;
}
.map-marker div[data-v-76252d8a] {
  box-shadow: 0 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
  background-color: #fff;
  transform: translateY(calc(-0.09375rem - 100%)) translateX(-0.02083333rem);
  position: absolute;
}
.map-marker div[data-v-76252d8a]::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.05208333rem;
  height: 0.05208333rem;
  bottom: 0;
  background-color: #fff;
  transform: rotate(45deg) translateY(0.04166667rem);
  box-shadow: 0.015625rem 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
}
.sale-ani[data-v-76252d8a] {
  animation-name: SaleService-76252d8a;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes SaleService-76252d8a {
0% {
    transform: scaleX(1);
}
10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
}
30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
}
40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
}
to {
    transform: scaleX(1);
}
}
.heart-ani[data-v-76252d8a] {
  animation-name: Heartbeat-76252d8a;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes Heartbeat-76252d8a {
0% {
    transform: scale(1);
}
14% {
    transform: scale(1.3);
}
28% {
    transform: scale(1);
}
42% {
    transform: scale(1.3);
}
70% {
    transform: scale(1);
}
}
.hover-scale .hover-holder[data-v-76252d8a] {
  overflow: hidden;
}
.hover-scale .hover-target[data-v-76252d8a] {
  transform: scale(1);
  transition: all .4s;
}
.hover-scale:hover .hover-target[data-v-76252d8a] {
  transform: scale(1.2);
}
.get-plan-toast-shadow[data-v-76252d8a] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.nav[data-v-76252d8a] {
  font-size: 0;
  position: relative;
  width: 100%;
}
.nav .item[data-v-76252d8a] {
  color: #333;
}
.parent[data-v-76252d8a] {
  position: relative;
  width: 100%;
  padding-top: 41.67%;
}
.nav-container[data-v-76252d8a] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.navheight[data-v-76252d8a] {
  height: 0.52083333rem;
  padding-top: 0;
}
.banner[data-v-76252d8a] {
  width: 100%;
}
.content[data-v-76252d8a] {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  height: 0.52083333rem;
  transition: .5s all;
}
.content .content-wrap[data-v-76252d8a] {
  margin: 0 auto;
}
.content-inner[data-v-76252d8a] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo[data-v-76252d8a] {
  width: 1.20833333rem;
  pointer-events: none;
}
.tabs[data-v-76252d8a] {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}
.list[data-v-76252d8a] {
  color: #fff;
  font-size: 0.125rem;
  display: flex;
  align-items: stretch;
  height: 0.52083333rem;
}
.query[data-v-76252d8a] {
  margin-left: 0.41666667rem;
}
.query .button[data-v-76252d8a] {
  width: 0.69270833rem;
  height: 0.52083333rem;
  display: none;
  cursor: pointer;
}
.phone-content[data-v-76252d8a] {
  display: flex;
}
.phone[data-v-76252d8a] {
  font-size: 0.125rem;
  color: #333;
  display: flex;
  align-items: center;
  margin-left: 0.3125rem;
  white-space: nowrap;
}
.phone .phone-icon[data-v-76252d8a] {
  width: 0.13541667rem;
  margin-right: 0.04166667rem;
  margin-left: 0.15625rem;
}
.white .phone[data-v-76252d8a] {
  color: #fff;
}
.item[data-v-76252d8a] {
  padding: 0 0.16666667rem;
  height: 0.52083333rem;
  line-height: 1;
  position: relative;
  cursor: pointer;
  font-size: 0.125rem;
  color: #666;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item .inner[data-v-76252d8a] {
  width: 100%;
  position: relative;
}
.item .inner[data-v-76252d8a]::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.02083333rem;
  background: transparent;
  top: 100%;
  left: 0;
  margin-top: 0.04166667rem;
}
.item:hover .child[data-v-76252d8a] {
  display: block;
}
.item .child[data-v-76252d8a] {
  position: absolute;
  top: 100%;
  padding-top: 0.08333333rem;
  left: 0.16666667rem;
  width: 4.16666667rem;
  display: none;
}
.item .child .child-inner[data-v-76252d8a] {
  box-shadow: 0.01041667rem 0.01041667rem 0.02604167rem #999;
  border-radius: 0.02083333rem;
}
.item .child .child-inner .child-content[data-v-76252d8a] {
  background: #fff;
  border-radius: 0.02083333rem 0.02083333rem 0.02083333rem 0.02083333rem;
  padding: 0.125rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.20833333rem 0;
  position: relative;
}
.item .child .child-inner .child-content .child-list[data-v-76252d8a] {
  width: 33%;
}
.item .child .child-inner .child-content .child-list .child-parent[data-v-76252d8a] {
  display: flex;
  align-items: center;
  gap: 0.04166667rem;
  font-size: 0.09375rem;
  color: #333;
  margin-bottom: 0.0625rem;
}
.item .child .child-inner .child-content .child-list .child-parent .child-icon[data-v-76252d8a] {
  width: 0.09375rem;
}
.item .child .child-inner .child-content .child-list .child-parent[data-v-76252d8a]:hover {
  color: #4e99fd;
}
.item .child .child-inner .child-content .child-list .child-item[data-v-76252d8a] {
  font-size: 0.07291667rem;
  color: #999;
  line-height: 0.11458333rem;
}
.item .child .child-inner .child-content .child-list .child-item[data-v-76252d8a]:hover {
  color: #4e99fd;
}
.item .child .child-inner .child-content .child-list .child-active[data-v-76252d8a] {
  color: #4e99fd;
}
.item .child .child-inner .child-content[data-v-76252d8a]::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 0.0625rem solid transparent;
  border-right: 0.0625rem solid transparent;
  border-bottom: 0.07291667rem solid #fff;
  bottom: 100%;
  left: 0.1875rem;
}
.item .child-sp[data-v-76252d8a] {
  width: 1.04166667rem;
}
.item .child-sp .child-list[data-v-76252d8a] {
  width: 100% !important;
}
.item[data-v-76252d8a]:hover,
.active[data-v-76252d8a] {
  color: #333;
}
.item:hover .inner[data-v-76252d8a]::after,
.active .inner[data-v-76252d8a]::after {
  background: #4e99fd;
}
.white .item[data-v-76252d8a] {
  color: #fff;
}
.white .item:hover .inner[data-v-76252d8a]::after,
.white .active .inner[data-v-76252d8a]::after {
  background: #fff;
}
.hover[data-v-76252d8a] {
  position: absolute;
  left: 50%;
  top: 0.52083333rem;
  height: calc(100% - 0.52083333rem);
  transform: translateX(-50%);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.hover .txt[data-v-76252d8a] {
  margin-top: 0.625rem;
  line-height: 1;
  padding: 0 0.10416667rem;
}
.hover .digital[data-v-76252d8a] {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  transform: translateY(-50%);
}
.banner-list[data-v-76252d8a] {
  width: 100%;
  height: 100%;
  position: relative;
}
.banner-list .banner-item[data-v-76252d8a] {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: .5s all;
  text-align: left;
}
.banner-list .banner-item .banner-limit[data-v-76252d8a] {
  max-width: 10rem;
  margin: 0 auto;
  padding: 0 8.5%;
  box-sizing: border-box;
  position: relative;
  top: 25%;
}
.banner-list .banner-item .banner-limit .banner-buttons[data-v-76252d8a] {
  display: flex;
  align-items: center;
  gap: 0.20833333rem;
}
.banner-list .banner-item .banner-limit .banner-button[data-v-76252d8a] {
  font-size: 0.16666667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.19791667rem;
  height: 0.33333333rem;
  border-radius: 0.20833333rem;
  margin-top: 0.15625rem;
  cursor: pointer;
  font-weight: bold;
  box-sizing: border-box;
}
.banner-list .banner-item .banner-limit .banner-button1[data-v-76252d8a] {
  background-color: transparent;
  color: #fff;
  border: 0.00520833rem solid #fff;
}
.banner-list .banner-item .banner-limit .banner-button1-c[data-v-76252d8a] {
  border-color: #096dd9;
  color: #096dd9;
}
.banner-list .banner-item .banner-limit .banner-button2[data-v-76252d8a] {
  color: #096dd9;
  background-color: #fff;
}
.banner-list .banner-item .banner-limit .banner-button2-c[data-v-76252d8a] {
  color: #fff;
  background-color: #096dd9;
}
.indicator[data-v-76252d8a] {
  position: absolute;
  bottom: 0.33333333rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 0.125rem;
}
.indicator .indicator-item[data-v-76252d8a] {
  width: 0.10416667rem;
  height: 0.10416667rem;
  border-radius: 50%;
  background: #fff;
  opacity: .4;
  cursor: pointer;
}
.indicator .indicator-item[data-v-76252d8a]:hover {
  opacity: .8;
}
.indicator .indicator-item-active[data-v-76252d8a] {
  opacity: .8;
}
.scrolling[data-v-76252d8a] {
  background: #fff;
  box-shadow: 0 0.00520833rem 0.05208333rem #999;
}
.scrolling .phone[data-v-76252d8a] {
  color: #333;
}
.scrolling .item[data-v-76252d8a] {
  color: #666;
}
.scrolling .item:hover .inner[data-v-76252d8a]::after,
.scrolling .active .inner[data-v-76252d8a]::after {
  background: #4e99fd;
}