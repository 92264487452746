@keyframes fadeIn-07371ffd {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
.opacity-enter-active[data-v-07371ffd] {
  animation: fadeIn-07371ffd .3s;
}
.opacity-leave-active[data-v-07371ffd] {
  animation: fadeIn-07371ffd .3s reverse;
}
.relative[data-v-07371ffd] {
  position: relative;
}
.w-full[data-v-07371ffd] {
  width: 100%;
}
.relative-center[data-v-07371ffd] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-07371ffd] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-07371ffd] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-07371ffd] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-07371ffd] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-07371ffd] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-07371ffd] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-07371ffd] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-07371ffd] {
  justify-content: space-between;
}
.flex[data-v-07371ffd] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-07371ffd] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-07371ffd],
.card-list-no-padding .ci-description[data-v-07371ffd] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.t-title-18[data-v-07371ffd] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 500;
  font-size: 0.09375rem;
  color: #fff;
  line-height: 0.09375rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.t-value-16[data-v-07371ffd] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #fff;
  line-height: 0.08333333rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
a[data-v-07371ffd] {
  text-decoration: none;
}
.map-marker[data-v-07371ffd] {
  position: relative;
}
.map-marker div[data-v-07371ffd] {
  box-shadow: 0 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
  background-color: #fff;
  transform: translateY(calc(-0.09375rem - 100%)) translateX(-0.02083333rem);
  position: absolute;
}
.map-marker div[data-v-07371ffd]::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.05208333rem;
  height: 0.05208333rem;
  bottom: 0;
  background-color: #fff;
  transform: rotate(45deg) translateY(0.04166667rem);
  box-shadow: 0.015625rem 0.015625rem 0.03125rem 0 rgba(0,0,0,.1608);
}
.sale-ani[data-v-07371ffd] {
  animation-name: SaleService-07371ffd;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes SaleService-07371ffd {
0% {
    transform: scaleX(1);
}
10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
}
30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
}
40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
}
to {
    transform: scaleX(1);
}
}
.heart-ani[data-v-07371ffd] {
  animation-name: Heartbeat-07371ffd;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes Heartbeat-07371ffd {
0% {
    transform: scale(1);
}
14% {
    transform: scale(1.3);
}
28% {
    transform: scale(1);
}
42% {
    transform: scale(1.3);
}
70% {
    transform: scale(1);
}
}
.hover-scale .hover-holder[data-v-07371ffd] {
  overflow: hidden;
}
.hover-scale .hover-target[data-v-07371ffd] {
  transform: scale(1);
  transition: all .4s;
}
.hover-scale:hover .hover-target[data-v-07371ffd] {
  transform: scale(1.2);
}
.get-plan-toast-shadow[data-v-07371ffd] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.banner[data-v-07371ffd] {
  width: 100%;
  height: 2.23958333rem;
  object-fit: cover;
}
.hover-pop[data-v-07371ffd] {
  margin-top: -1.82291667rem;
  position: relative;
}
.hover-pop-back[data-v-07371ffd] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(10,10,10,.6);
  z-index: 999;
}
.hover-pop-content[data-v-07371ffd] {
  margin: 0 auto;
  width: 6.6875rem;
  padding: 0.41666667rem 0.41666667rem 0.41666667rem 2.52083333rem;
  box-sizing: border-box;
  z-index: 999;
  display: flex;
  align-items: flex-start;
}
.hover-pop-left[data-v-07371ffd] {
  overflow: hidden;
  flex: 1;
}
.hover-pop-right[data-v-07371ffd] {
  flex: 1;
}
.hover-pop-title[data-v-07371ffd] {
  font-weight: 500;
  color: #096dd9;
  font-size: 0.3125rem;
  text-align: center;
  font-family: YouSheBiaoTiHei;
  line-height: 1;
}
.hover-pop-brief[data-v-07371ffd] {
  color: #096dd9;
  font-size: 0.15625rem;
  margin-top: 0.20833333rem;
  text-align: center;
  margin-bottom: 0.26041667rem;
  line-height: 1;
}
.hover-pop-item[data-v-07371ffd] {
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;
  color: red;
  border-radius: 0.02083333rem 0.02083333rem 0.02083333rem 0.02083333rem;
  border: 0.00520833rem solid #e8e8e8;
  padding: 0 0.0625rem;
  height: 0.26041667rem;
}
.hover-pop-item-sp[data-v-07371ffd] {
  height: 1.85416667rem;
  align-items: flex-start;
  padding: 0.0625rem;
  box-sizing: border-box;
}
.hover-pop-icon[data-v-07371ffd] {
  width: 0.10416667rem;
  flex-shrink: 0;
}
.hover-pop-txt[data-v-07371ffd] {
  width: 0.46875rem;
  font-size: 0.10416667rem;
  color: #4e99fd;
  margin: 0 0.08333333rem 0 0.04166667rem;
  flex-shrink: 0;
  text-align-last: justify;
  position: relative;
  margin-top: 0.05208333rem;
}
.hover-pop-txt[data-v-07371ffd]::after {
  content: ":";
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.hover-pop-input[data-v-07371ffd] {
  flex-grow: 1;
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  border: none;
  font-size: 0.08333333rem;
  height: 0.20833333rem;
  padding: 0 0.10416667rem;
  outline: none;
  color: #666;
  background-color: transparent;
  min-width: 0;
}
.hover-pop-input[data-v-07371ffd]::placeholder {
  color: #969393;
}
.hover-pop-check-icon[data-v-07371ffd] {
  width: 0.625rem;
  height: 0.20833333rem;
  cursor: pointer;
}
.hover-pop-area[data-v-07371ffd] {
  height: 100%;
  resize: none;
  box-sizing: border-box;
}
.hover-pop-button[data-v-07371ffd] {
  font-size: 0.125rem;
  color: #fff;
  width: 100%;
  height: 0.25rem;
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  text-align: center;
  line-height: 0.25rem;
  margin: 0 auto;
  margin-top: 0.20833333rem;
  cursor: pointer;
  letter-spacing: 0.10416667rem;
}
.hover-pop-close[data-v-07371ffd] {
  position: absolute;
  width: 0.08333333rem;
  right: 0.125rem;
  top: 0.125rem;
  cursor: pointer;
}
.private[data-v-07371ffd] {
  font-size: 0.09375rem;
  color: #999;
  text-align: left;
  margin-top: 0.30208333rem;
}
.private .sp[data-v-07371ffd] {
  color: #4e99fd;
  cursor: pointer;
}
.en-bannerTxt[data-v-07371ffd] {
  font-family: YouSheBiaoTiHei;
  font-size: 0.3125rem;
  color: #fff;
  margin-bottom: 0.20833333rem;
  text-align: center;
}
.bannerTxt[data-v-07371ffd] {
  font-size: 0.15625rem;
  color: rgba(255,255,255,.8);
  text-align: center;
}
.privateBox[data-v-07371ffd],
.hover-pop-success[data-v-07371ffd] {
  display: none;
}
.privateBox .private-back[data-v-07371ffd],
.hover-pop-success .private-back[data-v-07371ffd] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
  z-index: 999;
}
.privateBox .private-content[data-v-07371ffd],
.hover-pop-success .private-content[data-v-07371ffd] {
  position: fixed;
  width: 4.16666667rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.privateBox .private-content .private-title[data-v-07371ffd],
.hover-pop-success .private-content .private-title[data-v-07371ffd] {
  color: #fff;
  font-size: 0.08333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.3125rem;
  background: #4e99fd;
  position: relative;
}
.privateBox .private-content .private-title .private-close[data-v-07371ffd],
.hover-pop-success .private-content .private-title .private-close[data-v-07371ffd] {
  position: absolute;
  right: 0.125rem;
  top: 0.11458333rem;
  width: 0.08333333rem;
  cursor: pointer;
}
.privateBox .private-content .private-inner[data-v-07371ffd],
.hover-pop-success .private-content .private-inner[data-v-07371ffd] {
  font-size: 0.07291667rem;
  line-height: 2;
  background: #fff;
  text-align: left;
  padding: 0.20833333rem;
  padding-top: 0.05208333rem;
}
.privateBox .private-content .private-inner .private-h1[data-v-07371ffd],
.hover-pop-success .private-content .private-inner .private-h1[data-v-07371ffd] {
  color: #666;
}
.privateBox .private-content .private-inner .private-h2[data-v-07371ffd],
.hover-pop-success .private-content .private-inner .private-h2[data-v-07371ffd] {
  color: #333;
}
.privateBox .private-content .private-inner .private-h3[data-v-07371ffd],
.hover-pop-success .private-content .private-inner .private-h3[data-v-07371ffd] {
  color: #999;
}
.privateBox .private-content .private-inner .private-button[data-v-07371ffd],
.hover-pop-success .private-content .private-inner .private-button[data-v-07371ffd] {
  width: 0.75rem;
  height: 0.25rem;
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.10416667rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 0.26041667rem;
  cursor: pointer;
}
.hover-pop-success[data-v-07371ffd] {
  display: none;
}
.success-img[data-v-07371ffd] {
  width: 1.84375rem;
  margin: 0 auto;
  display: block;
}
.success-txt[data-v-07371ffd] {
  font-size: 0.08333333rem;
  color: #52c41a;
  text-align: center;
}